import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { FaTwitter, FaClipboard, FaCheck, FaChartBar } from 'react-icons/fa';
import memeData from '../data/memedata';
import DexScreenerIcon from '../icons/DEX Screener.png';
import CoinGeckoIcon from '../icons/Coingecko.png';
import DragonSwapIcon from '../icons/Dragon Swap.png';
import SEILogo from '../icons/SEI.png';

const cardHoverVariants = {
    hover: {
        scale: 1.05,
        transition: {
            duration: 0.3,
            ease: 'easeInOut',
        },
    },
};

const truncateAddress = (address) => {
    if (window.innerWidth < 768) {
        return `${address.slice(0, 6)}...${address.slice(-4)}`;
    }
    return address;
};

const MemeCoinCard = ({ logo, name, description, twitter, dexScreener, dragonSwap, coinGecko, pairAddress, contractAddress, onShowChart }) => {
    const [copied, setCopied] = useState(false);
    const [truncatedAddress, setTruncatedAddress] = useState(contractAddress);

    useEffect(() => {
        const handleResize = () => setTruncatedAddress(truncateAddress(contractAddress));
        handleResize();
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, [contractAddress]);

    const handleCopy = () => {
        navigator.clipboard.writeText(contractAddress)
            .then(() => setCopied(true))
            .catch(err => console.error('Failed to copy:', err))
            .finally(() => setTimeout(() => setCopied(false), 2000));
    };

    return (
        <motion.div
            initial={{ opacity: 0, y: 50, scale: 0.9 }}
            animate={{ opacity: 1, y: 0, scale: 1 }}
            exit={{ opacity: 0, y: -50, scale: 0.9 }}
            whileHover="hover"
            variants={cardHoverVariants}
            className="bg-gray-900 p-4 md:p-6 rounded-lg shadow-lg flex flex-col items-center space-y-4 mb-6 w-full max-w-sm md:max-w-2xl mx-auto relative"
        >
            <div className="flex justify-between items-center w-full relative">
                <div className="w-12 h-12 md:w-20 md:h-20 rounded-full overflow-hidden">
                    <img src={logo} alt={`${name} Logo`} className="w-full h-full object-cover" />
                </div>
                <div className="flex-grow text-center">
                    <h3 className="text-lg md:text-2xl font-bold flex justify-center items-center" style={{ marginTop: '0' }}>
                        {name}
                        <a href={twitter} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline ml-2">
                            <FaTwitter className="w-5 h-5" />
                        </a>
                    </h3>
                </div>
            </div>
            <div className="w-full mt-4">
                <hr className="border-gray-600 w-full" />
            </div>
            <div className="w-full text-left">
                <p className="mt-2 text-sm md:text-gray-400 mb-2">{description}</p>
            </div>
            <div className="w-full text-left">
                <div className="space-y-2">
                    <div className="flex items-center">
                        <img src={CoinGeckoIcon} alt="CoinGecko" className="w-4 h-4 mr-2" />
                        <a href={coinGecko} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline text-sm md:text-base">
                            CoinGecko
                        </a>
                    </div>
                    <div className="flex items-center">
                        <img src={DexScreenerIcon} alt="DEX Screener" className="w-4 h-4 mr-2" />
                        <a href={dexScreener} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline text-sm md:text-base">
                            DEX Screener
                        </a>
                    </div>
                    <div className="flex items-center">
                        <img src={DragonSwapIcon} alt="DragonSwap" className="w-4 h-4 mr-2" />
                        <a href={dragonSwap} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline text-sm md:text-base">
                            DragonSwap
                        </a>
                    </div>
                    <button
                        onClick={() => onShowChart(pairAddress)}
                        className="bg-gray-800 hover:bg-gray-600 text-white p-2 rounded-md flex items-center hidden md:flex"
                    >
                        <FaChartBar className="w-5 h-5 mr-2" />Chart</button>
                    <div className="text-sm md:text-base text-center">
                        <p className="mb-2">Contract Address:</p>
                        <div className="flex items-center justify-center space-x-2">
                            <a
                                href={`https://seitrace.com/address/${contractAddress}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="font-mono bg-gray-800 p-2 rounded-md text-white hover:underline"
                            >
                                {truncatedAddress}
                            </a>
                            <button
                                onClick={handleCopy}
                                className="bg-gray-700 hover:bg-gray-600 text-white p-2 rounded-md flex items-center"
                            >
                                {copied ? <FaCheck className="w-5 h-5" /> : <FaClipboard className="w-5 h-5" />}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </motion.div>
    );
};

const Meme = () => {
    const [showChart, setShowChart] = useState(false);
    const [currentPairAddress, setCurrentPairAddress] = useState(null);

    const handleShowChart = (pairAddress) => {
        setCurrentPairAddress(pairAddress);
        setShowChart(true);
    };

    const handleCloseChart = (e) => {
        if (e.target.id === 'chart-overlay' || e.target.id === 'close-chart') {
            setShowChart(false);
        }
    };

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === 'Escape') {
                setShowChart(false);
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    return (
        <div className="flex flex-col items-center justify-center text-white p-4 pb-16">
            <h1 className="text-2xl md:text-4xl font-bold text-center text-white mb-6 flex items-center justify-center">
                <img src={SEILogo} alt="SEI Logo" className="w-8 h-8 mr-2" /> Meme Coins
            </h1>
            <div className="hidden md:grid w-full max-w-5xl grid-cols-1 md:grid-cols-2 gap-6 mt-6">
                {memeData.map((coin, index) => (
                    <MemeCoinCard key={index} {...coin} onShowChart={handleShowChart} />
                ))}
            </div>
            <div className="md:hidden w-full">
                {memeData.map((coin, index) => (
                    <MemeCoinCard key={index} {...coin} onShowChart={handleShowChart} />
                ))}
            </div>
            {showChart && (
                <div
                    id="chart-overlay"
                    className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
                    onClick={handleCloseChart}
                >
                    <div className="bg-gray-900 p-4 rounded-lg relative w-[900px] h-[600px]" onClick={(e) => e.stopPropagation()}>
                        <style>
                            {`
                    #dexscreener-embed {
                        position: relative;
                        width: 100%;
                        height: calc(100% - 30px); /* Adjust height to leave space for the close text */
                    }
                    #dexscreener-embed iframe {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        top: 0;
                        left: 0;
                        border: 0;
                    }
                `}
                        </style>
                        <div id="dexscreener-embed">
                            <iframe
                                src={`https://dexscreener.com/seiv2/${currentPairAddress}?embed=1&theme=dark&trades=0&info=0`}
                                title="DEX Screener Chart"
                            ></iframe>
                        </div>
                        <div
                            className="text-center text-white mt-2 cursor-pointer"
                            onClick={() => setShowChart(false)}
                        >
                            Close
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Meme;
